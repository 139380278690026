export const adsHelper = {
    addAdsense: (attempt = 0) => {
        const retryTimes = [100, 1000, 2000, 3000, 4000]; // Tiempos de retry en ms
        const maxAttempts = retryTimes.length; // Máximo de intentos

        setTimeout(() => {
            // Verificar si el script de AdSense está cargado
            if (typeof window.adsbygoogle === 'undefined') {
                if (attempt < maxAttempts) {
                    adsHelper.addAdsense(attempt + 1);
                }
                return;
            }

            let ads = document.getElementsByClassName('adsbygoogle');
            let adsInitialized = 0;

            // Intentar inicializar los anuncios
            for (let i = 0; i < ads.length; i++) {
                if (!ads[i].hasAttribute('data-adsbygoogle-status')) {
                    try {
                        (adsbygoogle = window.adsbygoogle || []).push({});
                        adsInitialized++;
                    } catch (e) {}
                }
            }

            // Si no se encontraron anuncios o no se inicializó ninguno y aún hay intentos disponibles
            if (adsInitialized === 0 && attempt < maxAttempts) {
                adsHelper.addAdsense(attempt + 1);
            }
        }, retryTimes[attempt]);
    },
};

export const addAdsenseScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    const adsenseScript = document.createElement('script');
    adsenseScript.async = true;
    adsenseScript.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1884623822045383';
    adsenseScript.crossOrigin = 'anonymous';
    // adsenseScript.setAttribute('data-overlays', 'bottom');

    adsScriptsContainer.appendChild(adsenseScript);
};

export const addVideooTvScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    const videooScript = document.createElement('script');
    videooScript.defer = true;
    videooScript.id = 'display-library';
    videooScript.setAttribute('data-id', '5304a3dd388955b75ae57876347218c2a9e687f78faa26c74b806896a80290cf');
    videooScript.setAttribute('data-cfasync', 'false');
    videooScript.src =
        'https://display.videoo.tv/5304a3dd388955b75ae57876347218c2a9e687f78faa26c74b806896a80290cf.js';

    adsScriptsContainer.appendChild(videooScript);
};

export const addAdManagerScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    const adManagerScript = document.createElement('script');
    adManagerScript.async = true;
    adManagerScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    adManagerScript.crossOrigin = 'anonymous';

    adsScriptsContainer.appendChild(adManagerScript);
};

export const addHbAgencyScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://d3u598arehftfk.cloudfront.net/prebid_hb_2640_4004.js';

    //<script src="https://d3u598arehftfk.cloudfront.net/prebid_hb_2640_4004.js" async> </script>
    adsScriptsContainer.appendChild(script);
};

export const addAdeqMediaScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://t.seedtag.com/t/7457-5855-01.js';

    adsScriptsContainer.appendChild(script);
};

export const addGoogleTagManagerAndAnalytics = () => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-9D1Z119RGS';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-9D1Z119RGS');
};

export const addTaboolaScript = () => {
    // Obtén el contenedor de scripts por su ID
    const adsScriptsContainer = document.getElementById('ads-scripts');

    if (!adsScriptsContainer) return;

    // Evita agregar el script si ya existe
    if (document.getElementById('tb_loader_script')) return;

    // Inicializa el objeto de Taboola si no existe
    window._taboola = window._taboola || [];
    window._taboola.push({ category: 'auto' });

    const script = document.createElement('script');
    script.async = true;
    script.src = '//cdn.taboola.com/libtrc/analiticafantasy-publisher/loader.js';
    script.id = 'tb_loader_script';

    // Inserta el script antes del primer script en el contenedor
    adsScriptsContainer.appendChild(script);

    // Agrega una marca de rendimiento si está disponible
    if (window.performance && typeof window.performance.mark === 'function') {
        window.performance.mark('tbl_ic');
    }
};
