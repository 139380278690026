import https from 'https';

import axios, { HttpStatusCode } from 'axios';

import { Rutas } from '@/constants/rutas';
import { SITE_USER_COOKIE } from '@/constants/site';
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/cookies';

// Crear instancia de axios
const axiosApiInstance = axios.create({
    httpsAgent: new https.Agent({
        rejectUnauthorized: false, // Deshabilitar la validación de certificados SSL
    }),
});

// Interceptor de solicitudes
axiosApiInstance.interceptors.request.use(
    async (config) => {
        // Verificar si estamos en un entorno cliente antes de acceder a localStorage
        if (typeof window !== 'undefined') {
            const userJSON = getFromLocalStorage(SITE_USER_COOKIE);

            if (userJSON) {
                const user = JSON.parse(userJSON);
                const { token, email } = user;

                // Agregar headers de autenticación si existe un token
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                    config.headers.af_email = email;
                }
            }
        }

        // Agregar el header IsVercel
        config.headers.IsVercel = 'true';

        return config;
    },
    (error) => {
        // Manejar errores de solicitud
        if (error.response?.status === HttpStatusCode.Unauthorized) {
            removeFromLocalStorage(SITE_USER_COOKIE);
            window.location.href = Rutas.INICIO;
        }

        // Retornar el error para su manejo posterior
        return Promise.reject(error);
    }
);

export default axiosApiInstance;
