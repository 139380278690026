import { LEAGUES } from '@/constants/leagues';
import { currentSeason } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { alineaciones } from '@/helpers/analisis-plantilla-fantasy-helper';
import { FANTASY, FANTASY_MODE } from '@/helpers/biwenger-helper';
import {
    fantasyPositions,
    fantasySeasons,
    ligas,
    marketOptions,
    positions,
    seasons,
} from '@/helpers/leagues-helper';

export const initialState = {
    user: {
        logged: false,
        isAdmin: false,
        subscriptionType: SUBSCRIPTION_TYPE.FREE,
        email: null,
        role: null,
        token: null,
        username: null,
        nickname: null,
        isSecurity: false,
        version: null,
        prevUrl: null,
        currentUrl: null,
        image: '',
        urls: [],
        isTrialExpired: false,
    },
    app: {
        mobile: true,
        leftDrawer: false,
        rightDrawer: false,
        width: 0,
        height: 0,
        isScrollTop: true,
        isSpinnerVisible: false,
        ads: null,
        cookiesAccepted: false,
    },
    fixtures: {
        activeRoundIndex: 0,
        rounds: null,
    },
    config: {
        league: LEAGUES.LA_LIGA,
        season: currentSeason,
        seasons: seasons,
        ligas: ligas,
        positions: positions,
        fantasySeasons: fantasySeasons,
        marketOptions: marketOptions,
        defaultLeague: ligas.find((x) => x.value == LEAGUES.LA_LIGA),
        defaultSeason: seasons.find((x) => x.value == currentSeason),
        colors: {
            primary: '#FF0000',
            secondary: '#FF0000',
            tertiary: '#FF0000',
            riskVeryHigh: 'rgb(242 127 127)',
            riskHigh: 'rgb(208 172 108)',
            riskMedium: '#dddddd',
            riskLow: 'rgb(148 191 148)',
            riskVeryLow: 'rgb(192 216 237)',
        },
    },
    fantasy: {
        league: LEAGUES.LA_LIGA,
        season: currentSeason,
        positions: fantasyPositions,
        alineaciones: alineaciones,
        players: null,
        squad: null,
        ranking: null,
        player: null,
        formation: null,
        teams: null,
        ranking: null,
        scoreRound: null,
        scoreRounds: null,
        scoreFormation: null,
    },
    configuration: null,
    playerProfile: {
        playerId: null,
        fantasyId: null,
        selectedIndex: 1,
        allPlayers: null,
    },
    fantasyHistoric: {
        playerId: null,
        fantasyId: null,
        season: 2023,
    },
    preferences: {
        fantasy: 1,
        mode: 1,
        league: LEAGUES.LA_LIGA,
        lineupMode: FANTASY.INFORMATION,
        lineupModeOption: FANTASY_MODE.DIARIO_AS,
        lineupViewMode: 1,
        season: 2024,
        secondarySeason: 2023,
        isCountryLeague: false,
    },
    activities: {
        activities: [],
        skip: 0,
        take: 20,
        shouldRefresh: false,
    },
    ads: {},
    cacheState: {},
};
