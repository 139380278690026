import { useEffect } from 'react';

import { Roboto_Condensed } from 'next/font/google';

import Layout from '@/components/shared/layout';
import '@/styles/external.scss';
import '@/styles/base.scss';
import SpinnerLayout from '@/components/shared/spinner-layout';
import { Provider } from '@/context';

// Configuramos la fuente con los pesos y el subconjunto que necesitamos
const robotoCondensed = Roboto_Condensed({
    subsets: ['latin'],
    weights: ['300', '400', '500', '600'],
    display: 'swap',
});

export default function App({ Component, pageProps }) {
    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
            const isNotDev = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';
            if (isNotDev) {
                navigator.serviceWorker.getRegistration('/sw.js').then((existingRegistration) => {
                    if (!existingRegistration) {
                        navigator.serviceWorker
                            .register('/sw.js')
                            .then((registration) => {
                                console.log('Service Worker registrado:', registration);
                            })
                            .catch((error) => {
                                console.error('Error al registrar el Service Worker:', error);
                            });
                    } else {
                        console.log('Service Worker ya está registrado.');
                    }
                });
            }
        }
    }, []);

    return (
        <section className={`analitica-fantasy-site ${robotoCondensed.className}`}>
            <Provider>
                <Layout>
                    <SpinnerLayout>
                        <Component {...pageProps} />
                    </SpinnerLayout>
                </Layout>
            </Provider>
        </section>
    );
}
