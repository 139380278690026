import { useContext, useEffect } from 'react';

import { Context } from '@/context';

export const MoneyAdType = {
    MEGABANNER: 1, // 728x90
    ROBAPAGINAS: 2, // 300x250
    SPLITSCREEN: 3,
    SKYSCRAPER: 4,
    SLIDE_IN: 6,
    IN_TEXT: 11, // NO RECOMENDADO
    CONTENIDO_RECOMENDADO: 16,
    ROBAPAGINAS_INFERIOR: 19,
    MEGASKYSCRAPER: 20,
    MEGABANNER_INFERIOR: 28,
    BILLBOARD: 31,
    CORNER_VIDEO: 38,
};

const MoneyAd = ({ type }) => {
    const isProd = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';
    const { state } = useContext(Context);
    const { app } = state;

    useEffect(() => {
        if (isProd && app.cookiesAccepted) {
            try {
                const container = document.getElementById(`98781-${type}`);

                // Si no se encuentra el contenedor, no agregar scripts
                if (!container) return;

                // Crear y agregar el primer script
                const genScript = document.createElement('script');
                genScript.src = `//ads.themoneytizer.com/s/gen.js?type=${type}`;
                genScript.async = true;
                container.appendChild(genScript);

                // Crear y agregar el segundo script
                const reqScript = document.createElement('script');
                reqScript.src = `//ads.themoneytizer.com/s/requestform.js?siteId=98781&formatId=${type}`;
                reqScript.async = true;
                container.appendChild(reqScript);

                // Limpiar los scripts al desmontar el componente
                return () => {
                    container.innerHTML = ''; // Limpia los scripts inyectados
                };
            } catch (e) {
                console.log(e);
            }
        }
    }, [isProd, app.cookiesAccepted, type]);

    if (!isProd) {
        return null; // No renderizar nada si no es producción
    }

    const getContenidoRecomendadoClass = () => {
        return type === MoneyAdType.CONTENIDO_RECOMENDADO ? 'outbrain-tm' : '';
    };

    return (
        <div className="money-ad-parent">
            <div className={`money-ad-${type} ${getContenidoRecomendadoClass()}`} id={`98781-${type}`}></div>
        </div>
    );
};

export default MoneyAd;
