const URL_FACTORY = {
    // Fantasy BLOG URLS

    GET_URL_EstadisticasGlobales: (temporada, jornadaActiva) => {
        const url = `${
            process.env.NEXT_PUBLIC_URL_FUNCTION
        }lfmPlayers/playersStats?temporada=${temporada}&jornadaActiva=${jornadaActiva ?? -1}`;
        return url;
    },
    GetTeamImageLogo: (teamSlug, customWidth) => {
        const width = customWidth ?? 51;
        if (teamSlug) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/teams/${teamSlug}.png${width ? `?width=${width}` : ''}`;
        }

        return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png${width ? `?width=${width}` : ''}`;
    },
    GetTeamImage: (teamSlug) => {
        const width = 51;
        if (teamSlug) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/teams/${teamSlug}.png${width ? `?width=${width}` : ''}`;
        }

        return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png${width ? `?width=${width}` : ''}`;
    },
    GetPlayerTemplateImage: () => `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`,
    GetCountryImage: (countryCode, width) =>
        `${process.env.NEXT_PUBLIC_IMAGES}/countries/${countryCode}.png?width=${width ?? 15}`,
    GetUniquePlayerImage: (image, width, playerId) => {
        if (!image && playerId) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${playerId}.png`;
        }
        if (image.includes('-')) {
            return image;
        }

        const url = `${image}${width ? `?width=${width}` : ''}`;
        return url;
    },

    // Accounts
    ConfirmEmail: (token) => `${process.env.NEXT_PUBLIC_API}/accounts/confirm-email/${token}`,
    DeleteAccount: `${process.env.NEXT_PUBLIC_API}/accounts/delete-account`,
    ForgotPassword: (username) => `${process.env.NEXT_PUBLIC_API}/accounts/forgot-password/${username}`,
    ResetPassword: `${process.env.NEXT_PUBLIC_API}/accounts/reset-password`,
    ResendEmailConfirmation: `${process.env.NEXT_PUBLIC_API}/accounts/verify-email`,
    RegisterUserUrl: `${process.env.NEXT_PUBLIC_API}/accounts/register`,
    LoginUserUrl: `${process.env.NEXT_PUBLIC_API}/accounts/login`,
    UpdatePassword: `${process.env.NEXT_PUBLIC_API}/accounts/update-user-password`,
    UserProfile: `${process.env.NEXT_PUBLIC_API}/accounts/user-profile`,
    UpsertAccount: `${process.env.NEXT_PUBLIC_API}/accounts/upsert-account`,
    GetAccounts: `${process.env.NEXT_PUBLIC_API}/accounts/accounts`,
    UploadAccountImage: `${process.env.NEXT_PUBLIC_API}/images/upload-account-image`,
    SetUserTrial: `${process.env.NEXT_PUBLIC_API}/accounts/trial`,

    // Biwenger
    GetBiwengerEstadisticas: `${process.env.NEXT_PUBLIC_API}/biwenger/estadisticas`,
    GetBiwengerPlayers: `${process.env.NEXT_PUBLIC_API}/biwenger/players`,

    // Calendario Admin
    GetFantasyAdminCalendar: `${process.env.NEXT_PUBLIC_API}/calendario/admin`,
    SaveAdminCalendar: `${process.env.NEXT_PUBLIC_API}/calendario/admin/save`,
    ObtenerCalendarioFantasy: `${process.env.NEXT_PUBLIC_API}/calendario/fantasy`,

    // Chollos
    ChollosFantasy: `${process.env.NEXT_PUBLIC_API}/chollos-fantasy`,

    // Fantasy Players
    GET_URL_FANTASY_MARKET_VALUES: `${process.env.NEXT_PUBLIC_API}/fantasy-players/mercado`,
    GetFantasyPlayer: `${process.env.NEXT_PUBLIC_API}/fantasy-players/fantasy-player`,
    GET_URL_FANTASY_LAST_MARKET_VALUES: (id) =>
        `${process.env.NEXT_PUBLIC_API}/fantasy-players/market-values/${id}`,
    GET_URL_FantasyPlayers: `${process.env.NEXT_PUBLIC_API}/fantasy-players`,
    GetHistoricFantasy: `${process.env.NEXT_PUBLIC_API}/fantasy-players/historic`,
    MejoresRachas: `${process.env.NEXT_PUBLIC_API}/fantasy-players/mejores-rachas`,

    // Configurations
    GetConfiguration: `${process.env.NEXT_PUBLIC_API}/configuration`,
    SaveConfiguration: `${process.env.NEXT_PUBLIC_API}/configuration`,

    // Votes
    GetVotes: `${process.env.NEXT_PUBLIC_API}/votes`,
    AddVote: `${process.env.NEXT_PUBLIC_API}/votes/add`,

    // Convocatorias
    GetConvocatorias: `${process.env.NEXT_PUBLIC_API}/convocatoria`,
    UpdateConvocatorias: `${process.env.NEXT_PUBLIC_API}/convocatoria/update`,
    PublishConvocatorias: `${process.env.NEXT_PUBLIC_API}/convocatoria/publish`,
    GetPublishedConvocatoria: `${process.env.NEXT_PUBLIC_API}/convocatoria/published`,

    // Fantasy Analysis
    URL_ExecuteAnalysis: `${process.env.NEXT_PUBLIC_API}/fantasy-analysis/v2`,

    // Fantasy Squad
    DeleteFantasySquad: (id) => `${process.env.NEXT_PUBLIC_API}/fantasy-squad/${id}`,
    GET_URL_FANTASY_SQUAD: (id) => `${process.env.NEXT_PUBLIC_API}/fantasy-squad/${id}`,
    GetFantasySquads: `${process.env.NEXT_PUBLIC_API}/fantasy-squads`,
    GetSharedFantasySquad: (code) => `${process.env.NEXT_PUBLIC_API}/fantasy-squad/shared/${code}`,
    ShareFantasySquads: `${process.env.NEXT_PUBLIC_API}/fantasy-squad/share`,
    UpsertFantasySquad: `${process.env.NEXT_PUBLIC_API}/fantasy-squad/upsert`,

    // Fantasy Stats
    GetEstadisticasFantasy: `${process.env.NEXT_PUBLIC_API}/fantasy-stats/get-fantasy-estadisticas`,
    GET_URL_GetFantasyPlayersStats: `${process.env.NEXT_PUBLIC_API}/fantasy-stats/get-fantasy-stats`,
    ObtenerPuntuacionesJornada: (season, jornada) =>
        `${process.env.NEXT_PUBLIC_API}/puntos-fantasy/jornada/${season}/${jornada}`,

    // Fixture Lineups
    GET_URL_ManagerLineups: `${process.env.NEXT_PUBLIC_API}/manager-lineups`,
    UpdateAlineacionesConfirmadas: `${process.env.NEXT_PUBLIC_API}/alineaciones-confirmadas/update`,

    // Fixture Section
    GetFixtureBox: (fixtureId) => `${process.env.NEXT_PUBLIC_API}/fixture-box/${fixtureId}`,
    GetFixtureFantasyStats: (fixtureId) =>
        `${process.env.NEXT_PUBLIC_API}/fixture-fantasy-stats/${fixtureId}`,
    GetFixturePrediction: (fixtureId) => `${process.env.NEXT_PUBLIC_API}/fixture-predictions/${fixtureId}`,

    // Fixtures
    POST_URL_TeamCalendar: `${process.env.NEXT_PUBLIC_API}/fixtures/team`,
    POST_URL_GetFixtures: `${process.env.NEXT_PUBLIC_API}/fixtures`,
    GetFixturesAlineacionesProbables: `${process.env.NEXT_PUBLIC_API}/next-admin-lineups`,
    GetSeoFixture: (fixtureId) => `${process.env.NEXT_PUBLIC_API}/seo-fixture/${fixtureId}`,
    GET_URL_RecentFixtures: `${process.env.NEXT_PUBLIC_API}/fixtures/recent`,
    UploadImage: (playerId) => `${process.env.NEXT_PUBLIC_API}/images/upload-image/${playerId}`,
    GetLanzadoresDePenaltis: `${process.env.NEXT_PUBLIC_API}/lanzadores-penaltis`,
    GetFixtures: `${process.env.NEXT_PUBLIC_API}/fixtures`,

    // Lineups
    GetAdminLineups: `${process.env.NEXT_PUBLIC_API}/lineups/admin`,
    SaveAdminLineups: `${process.env.NEXT_PUBLIC_API}/lineups/admin/update`,
    GET_URL_AlineacionesProbables_Initial: `${process.env.NEXT_PUBLIC_API}/lineups/initial`,
    GET_URL_UpdateLineup: `${process.env.NEXT_PUBLIC_API}/lineups/update`,
    GetFullLineups: `${process.env.NEXT_PUBLIC_API}/lineups`,

    // Noticias Flash
    GetNoticiasFlash: `${process.env.NEXT_PUBLIC_API}/noticias-flash`,
    GetNoticiasFlashAdmin: `${process.env.NEXT_PUBLIC_API}/noticias-flash-admin`,
    DeleteNoticiasFlashAdmin: (id) => `${process.env.NEXT_PUBLIC_API}/noticia-flash/${id}`,
    EditNoticiasFlashAdmin: `${process.env.NEXT_PUBLIC_API}/noticia-flash/update`,

    // Once tipo
    GetOnceTipo: `${process.env.NEXT_PUBLIC_API}/once-tipo-admin`,
    SaveOnceTipo: `${process.env.NEXT_PUBLIC_API}/upsert-once-tipo-admin`,
    GetOnceTipoUser: (teamId) => `${process.env.NEXT_PUBLIC_API}/once-tipo/${teamId}`,

    // Oraculo Fantasy
    GetOraculoFantasy: `${process.env.NEXT_PUBLIC_API}/oraculo-fantasy`,

    // Players
    URL_AnalisisPlantillaV2: `${process.env.NEXT_PUBLIC_API}/players`,
    GetAllPlayers: `${process.env.NEXT_PUBLIC_API}/players/all`,
    GetPlayersByStatus: `${process.env.NEXT_PUBLIC_API}/players/status`,
    GetPlayersForImage: `${process.env.NEXT_PUBLIC_API}/players/v2`,
    SearchPlayer: `${process.env.NEXT_PUBLIC_API}/players/search`,
    SyncPlayerImages: (id) => `${process.env.NEXT_PUBLIC_API}/players/sync-images/${id}`,
    GET_URL_ChangePlayerStatus: `${process.env.NEXT_PUBLIC_API}/players/upsert`,
    UpdateParteMedico: (playerId) => `${process.env.NEXT_PUBLIC_API}/players/update-parte-medico/${playerId}`,
    UpdateParteMedico: (playerId) => `${process.env.NEXT_PUBLIC_API}/players/update-parte-medico/${playerId}`,
    UpsertAdminFantasyPlayers: `${process.env.NEXT_PUBLIC_API}/fantasy-players/admin`,

    // Players Profile
    GetPlayersProfile: `${process.env.NEXT_PUBLIC_API}/players-profile`,
    GetPlayerProfile: `${process.env.NEXT_PUBLIC_API}/players/profile`,

    // Predicting manager
    GET_URL_Predicting_Manager_Ranking: `${process.env.NEXT_PUBLIC_API}/predicting-scores/ranking`,

    // Puja Fantasy
    PujaFantasy: `${process.env.NEXT_PUBLIC_API}/puja-fantasy`,
    GetStanding: `${process.env.NEXT_PUBLIC_API}/standing`,

    // Stripe
    CreateCheckoutSession: `${process.env.NEXT_PUBLIC_API}/stripe/create-checkout-session`,
    GetPayment: (paymentId) => `${process.env.NEXT_PUBLIC_API}/stripe/payment/${paymentId}`,

    // Titularidades
    ObtenerTitularidades: `${process.env.NEXT_PUBLIC_API}/titularidades`,

    // Tierlists
    PublicTierlists: `${process.env.NEXT_PUBLIC_API}/tierlists/public`,
    ListTierlists: `${process.env.NEXT_PUBLIC_API}/tierlist`,
    ViewTierlist: (slug) => `${process.env.NEXT_PUBLIC_API}/tierlist/ver-tierlist/${slug}`,
    GetTierlist: (slug) => `${process.env.NEXT_PUBLIC_API}/tierlist/${slug}`,
    CreateTierlist: `${process.env.NEXT_PUBLIC_API}/tierlist`,
    DeleteTierlist: (id) => `${process.env.NEXT_PUBLIC_API}/tierlist/${id}`,
    GetSecondaryInfo: `${process.env.NEXT_PUBLIC_API}/tierlist/secondary-info`,

    // Noticias
    GetActivities: `${process.env.NEXT_PUBLIC_API}/activities`,
    URL_GET_PUBLISHED_ARTICLES: `${process.env.NEXT_PUBLIC_API}/noticias/noticias-publicadas`,
    URL_GET_RECENT_ARTICLES: `${process.env.NEXT_PUBLIC_API}/noticias/noticias-publicadas`,
    GetColaboradores: `${process.env.NEXT_PUBLIC_API}/colaboradores`,
    URL_Survey: `${process.env.NEXT_PUBLIC_API}/encuesta`,
    URL_CREAR_NOTICIAS: `${process.env.NEXT_PUBLIC_API}/noticias/crear-noticia`,

    // Virus FIFA
    GetVirusFifa: `${process.env.NEXT_PUBLIC_API}/virus-fifa/convocatoria`,
    UpdateVirusFifa: `${process.env.NEXT_PUBLIC_API}/virus-fifa/convocatoria`,

    // Contact
    Contact: `${process.env.NEXT_PUBLIC_API}/contact-us`,

    // Planificador Jornadas
    GetPlanificadorJornadas: `${process.env.NEXT_PUBLIC_API}/planificador-jornadas`,
};

export default URL_FACTORY;
