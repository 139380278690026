import { useEffect } from 'react';

import { adsHelper } from '@/helpers/ads-helper';

const VideooRefresherDynamic = ({ id }) => {
    useEffect(() => {
        adsHelper.addAdsense();
    }, [id]);
    return (
        <div className="adsense-responsive-container">
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-1884623822045383"
                data-ad-slot={2829118637}
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default VideooRefresherDynamic;
