import { Fragment } from 'react';

import { Divider, Typography } from '@mui/material';

import CustomImageComponent from '@/components/shared/custom/custom-image';
import RedDotBlinking from '@/components/shared/custom/in-progress';
import { translate } from '@/data/fixture-transalations';
import { getTimeShortWithMinutes } from '@/helpers/datetime-helper';
import {
    getScoreByFixtureStatus,
    getDisplayLiveIndicator,
    getFixtureStatusCard,
} from '@/helpers/fixtures-helper';
import { getTeamImageById } from '@/helpers/images-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';

const FixtureScore = ({ goals, fixtureStatus }) => (
    <div className="fixtures-card-new__teams__score">
        <div className="game-score">
            <Typography className="game-score__number">
                {getScoreByFixtureStatus(goals, fixtureStatus)}
            </Typography>
        </div>
    </div>
);

const FixtureCard = ({ fixture, handleFixture }) => (
    <div
        className="clickable-element-game af-col-6"
        onClick={() => {
            if (handleFixture) handleFixture(fixture);
        }}
    >
        <div className="clickable-element-game__header">
            <div className="clickable-element-game__left">
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <CustomImageComponent
                        src={getCompetitionImage(fixture.leagueId, 35)}
                        alt={`liga`}
                        width={15}
                        height={15}
                    />
                    <Typography className="text-14">{translate(fixture.round, true)}</Typography>
                </div>
                <Typography style={{ fontSize: '12px' }}>{getTimeShortWithMinutes(fixture.date)}</Typography>
            </div>
        </div>
        <Divider />
        <div className="clickable-element-game__fixtures">
            <div className="clickable-element-game__fixture" style={{ justifyContent: 'flex-end' }}>
                <div className="fixtures-card-new__teams__local__name">
                    <CustomImageComponent
                        className="fixtures-card-new__teams__local__image"
                        src={getTeamImageById(fixture?.home?.id, 45)}
                        alt={`equipo`}
                        width={30}
                        height={30}
                    />
                </div>
            </div>
            <div className="clickable-element-game__score">
                <FixtureScore goals={fixture.home.goals} fixtureStatus={fixture.fixtureStatus} />
                <FixtureScore goals={fixture.away.goals} fixtureStatus={fixture.fixtureStatus} />
            </div>
            <div className="clickable-element-game__fixture">
                <div className="fixtures-card-new__teams__local__name">
                    <CustomImageComponent
                        className="fixtures-card-new__teams__local__image"
                        src={getTeamImageById(fixture?.away?.id, 56)}
                        alt={`equipo`}
                        width={30}
                        height={30}
                    />
                </div>
            </div>
        </div>
        <Divider />
        <div className="clickable-element-game__status">
            {getDisplayLiveIndicator(fixture.fixtureStatus) && <RedDotBlinking />}
            <Typography className={`matches-fantasy-text clickable-text-link text-14`}>
                {getFixtureStatusCard(
                    fixture.fixtureStatus,
                    fixture.fixtureLineupsAvailable,
                    fixture.leagueId
                )}
            </Typography>
        </div>
    </div>
);

const FixturesNewCardComponent = ({ fixtures, handleFixture }) => {
    return (
        <div className="games-content">
            {fixtures?.map((fixture, index) => (
                <Fragment key={index}>
                    <FixtureCard fixture={fixture} handleFixture={handleFixture} />
                </Fragment>
            ))}
        </div>
    );
};

export default FixturesNewCardComponent;
